<template>
  <blocked-content-wrapper :blocked="blocked" class="buy_screen">
    <loader v-if="infoProcessing"/>
    <template v-else>
      <template v-if="!exist">
        <div
            v-if="orderData"
            class="payment_grid"
            :class="{reverse: freeAccess}"
        >
          <stripe-card
              class="stripe"
              v-if="!freeAccess && !isConfirmStep"
              :name="orderData.name"
              :price="orderData.price"
              @payment_method="prepare"
              @errors="onStripeError"
              @first_change="onFirstChange"
              ref="card"
              @confirmCardCb="this.setConfirmCardCb"
          />
          <order-confirmation
              v-if="isConfirmStep"
          />
          <div class="preview">
            <order-review
                :summary="summary"
                :orderData="orderData"
                :id="routeQuery.id"
                :type="routeQuery.type"
                :needInput="!isConfirmStep"
                @promoChange="onPromoChange"
            />
            <div class="policy">
              <terms-policy-popup/>
              <div class="warning-text">
                <p>
                  By completing this transaction you agree to the following:
                </p>
                <p>
                  All sales are final. There are no refunds or allowances. <br/>
                  Your subscription will auto-renew unless you cancel that subscription. You can find this option on the Account page. <br/>
                  You may cancel your subscription at anytime
                </p>
              </div>
            </div>
          </div>
          <div v-if="!isConfirmStep"
               class="action"
          >
            <div class="submit_label">
              <span>Proceed to payment</span><hr/>
            </div>
            <button-component
                @btn-click="submitBtn.action"
                class="submit_button"
                :disabled="paymentProcessing"
            >
              {{submitBtn?.title}}
            </button-component>
            <div v-if="errors" class="error">{{errors}}</div>
            <div class="process_encrypted">
              Our payment process is encrypted with 256 bit encryption and
              SSL secure connect
            </div>
            <stripe-label/>
          </div>
          <div v-else>
            <app-link
                :to="{name: 'home'}"
                class="home_btn"
            >
              Click here to return to home
            </app-link>
          </div>
          <div v-if="paymentProcessing" class="payment_processing">
            <div>
              <p>Your order is being processed.</p>
              <p>Please do not refresh the page</p>
            </div>
            <loader/>
          </div>
        </div>
      </template>
      <template v-else>
        You already bought this {{routeQuery.type}}.<br>
        Please check your active {{routeQuery.type}}s in <app-link to="/profile">profile</app-link>
      </template>
      <div v-if="showRepeatModal"
           class="repeat"
           @click.stop.prevent="closeModal"
      >
        <div class="repeat-popup" @click.stop.prevent>
          <div class="close" @click.prevent.stop="closeModal">
            <x-mark-icon/>
          </div>
          <h3>{{ $t('whoops') }}</h3>
          <h3 class="mb-5">{{ $t('something wrong') }}</h3>
          <button-component
              @btn-click="repeatPayment"
          >
            {{ $t('try again') }}
          </button-component>
        </div>
      </div>
    </template>
  </blocked-content-wrapper>
</template>

<script>
  import StripeCard from "../components/purchase/StripeCard";
  import {mapActions, mapMutations, mapState} from "vuex";
  import ButtonComponent from "../components/base-components/buttons/ButtonComponent";
  import {ORDER_STATUS, ORDER_TYPE} from "../const";
  import StripeLabel from "../assets/img/stripeLabel";
  import OrderReview from "../components/purchase/OrderReview";
  import {mapCacheActions} from "vuex-cache";
  import AppLink from "../components/base-components/AppLink";
  import GoogleAnalytics from "../components/base-components/Analytics/GoogleAnalytics";
  import XMarkIcon from "../assets/img/XMarkIcon";
  import BlockedContentWrapper from "../components/base-components/BlockedContentWrapper";
  import Loader from "../components/Loader";
  import OrderConfirmation from "../components/purchase/OrderConfirmation";
  import TermsPolicyPopup from "../components/base-components/termsPolicyPopup";

  export default {
    name: "BuyScreen",
    components: {
      TermsPolicyPopup,
      OrderConfirmation,
      Loader,
      BlockedContentWrapper,
      XMarkIcon,
      AppLink,
      OrderReview,
      StripeLabel,
      ButtonComponent,
      StripeCard
    },
    data() {
      return {
        orderData: null,
        trackData: null,
        confirmCard: null,
        exist: false,
        promo: null,
        freeAccess: false,
        paymentProcessing: false,
        errors: '',
        isConfirmStep: false,
        showRepeatModal: false,
        clearCb: null,
        infoProcessing: false,
      }
    },
    methods: {
      ...mapActions({
        paymentPrepare: 'PAYMENT_PREPARE',
        getOrdersInfo: 'GET_INFO_FOR_ORDER_BY_TYPE',
        requestPay: 'PAYMENT_PAY',
        orderPay: 'ORDER_PAY',
        getEvent: 'GET_EVENT',
        getOrderById: 'GET_ORDER_BY_ID',
      }),
      ...mapCacheActions({
        getParticipantsList: 'GET_PARTICIPANTS_LIST'
      }),
      ...mapMutations({
        addToast: 'ADD_TOAST',
        clearCache: 'CLEAR_CACHE'
      }),
      //STRIPE START
      onStripeError(errorText) {
        this.errors = errorText;
      },
      onSubmit(){
        this.prepare()
      },
      setConfirmCardCb(cb){
        this.confirmCard = cb
      },
      requestCardConfirm(orderData, e = null){
        const promise = this.confirmCard?.(orderData, e);
        if (promise){
          promise.then((result) => {
            if (result.setupIntent) {
              this.pay(orderData.order_id, result.setupIntent.payment_method, e);
            } else {
              e?.complete('fail');
              this.paymentProcessing = false;
              this.errors = result?.error?.message || "Undefined error";
              this.trackFinishPurchase(this.errors)
            }
          })
        }
      },
      prepare(e) {
        const params = Object.fromEntries(Object.entries({
          type: this.routeQuery.type,
          id: this.routeQuery.id,
          participant_id: this.$route.query.participant_id,
          single_event_package_id: this.orderData.single_event_package_id,
        }).filter(([_, val]) => val));

        this.paymentPrepare(params).then(data => {
          this.initBuyOrder({transaction_id: data?.data?.order_id});

          if (data.data && data.data.client_secret) {
            this.paymentProcessing = true;
            this.requestCardConfirm(data.data, e);
          }
        }).catch(error => {
          e?.complete?.('fail');
          if (error.message) {
            this.errors = error.message;
          }
          this.trackFinishPurchase(error.message);
          this.paymentProcessing = false;
        });
      },
      async pay(order_id, payment_method, e = null){
        const {
          payPromise,
          clearIntervalCb,
        } = await this.orderPay({
          order_id,
          payment_method,
          promocode: this.promo?.couponName,
          event: e,
          type: this.routeQuery.type,
          single_event_package_id: this.routeQuery.single_event_package_id,
          id: this.routeQuery.id,
        })
        this.clearCb = clearIntervalCb;

        payPromise
            .then(() => {
              this.isConfirmStep = true;
              this.trackFinishPurchase();
            })
            .catch((err) => {
              if (err.popup && err.status === ORDER_STATUS.failed) {
                this.showRepeatModal = true;
              } else {
                this.trackFinishPurchase(err.message);
                this.$router.push({name: 'ErrorPage'})
              }
            })
            .finally(() => {
              this.paymentProcessing = false;
            })
      },
      //STRIPE END

      //GOOGLE
      initBuyOrder({transaction_id}){
        this.trackData.transaction_id = transaction_id;
        GoogleAnalytics.processPurchase({
          ...this.trackData,
          ...(this.promo || {})
        })
      },
      trackFinishPurchase(isError){
        const params = this.trackData;
        if(typeof isError === 'string'){
          params.error_reason = isError
        }
        isError? GoogleAnalytics.failedPurchase(params) : GoogleAnalytics.successPurchase(params)
      },
      onFirstChange() {
        GoogleAnalytics.beginCheckout({
          ...this.trackData,
          ...(this.promo || {})
        })
      },
      //GOOGLE END

      // OTHER
      loadPurchaseInfo(){
        if (this.routeQuery){
          const props = {
            type: this.routeQuery.type,
            id: this.routeQuery.id,
            single_event_package_id: this.routeQuery.single_event_package_id,
            participant_id: this.$route.query.participant_id,
          }

          const infoPromise = this.getOrdersInfo(props)
          this.infoProcessing = true;

          infoPromise.then(({ trackData, orderData }) => {
            this.orderData = orderData;
            this.trackData = trackData;
            this.exist = orderData.exist;

            if (this.exist && this.routeQuery.type === ORDER_TYPE.event){
              return this.$router.push({name: 'watch', params: {id: this.routeQuery.id}});
            }

            GoogleAnalytics.beginPurchase({
              ...trackData,
              from: this.$storage.get('form_fullPath'),
            })
          }).catch(() => {
            this.$router.push({name: 'ErrorPage'});
          }).finally(() => {
            this.infoProcessing = false;
          })
        } else {
          this.$router.push({name: 'ErrorPage'});
        }
      },
      onPromoChange(data){
        this.promo = data;
        this.freeAccess = data.freeAccess
      },
      getFree() {
        this.paymentProcessing = true;
        this.paymentPrepare(this.orderData).then(data => {
          this.pay(data.data.order_id);
        }).catch(error => {
          if (error.message) {
            this.errors = error.message;
          }
          this.paymentProcessing = false;
        });
      },

      //MODAL
      closeModal(e){
        e && this.trackFinishPurchase('order check error')
        this.showRepeatModal = false;
      },
      repeatPayment() {
        this.onSubmit();
        this.closeModal();
      },
    },
    computed:{
      ...mapState({
        participants: state => state.participant.participantsList,
      }),
      summary(){
        if (this.routeQuery.type === ORDER_TYPE.package){
          let participant = this.participants?.find((item) => {
            return Number(item.id) === (Number(this.$route.query.participant_id))
          });
          return participant? `Team: ${participant.name}`: null
        }
      },
      routeQuery(){
        if (this.$route.query.event_id){
          return {
            type: ORDER_TYPE.event,
            id: this.$route.query.event_id,
            single_event_package_id: this.$route.query?.single_event_package_id,
          }
        } else if (this.$route.query.package_id) {
          return  {
            type: ORDER_TYPE.package,
            id: this.$route.query.package_id,
          }
        } else if (this.$route.query.subscription_id) {
          return  {
            type: ORDER_TYPE.subscription,
            id: this.$route.query.subscription_id,
          }
        }
      },
      blocked(){
        return this.trackData?.item?.is_geo_blocked
      },
      submitBtn() {
        return this.freeAccess? {
          title: "Get it free",
          action: this.getFree,
        } : {
          title: "Submit Order",
          action: this.onSubmit,
        }
      }
    },
    created(){
      if (!this.$store.getters.isLogged) {
        return this.$router.push({name: 'login'});
      }
      this.getParticipantsList();
      this.loadPurchaseInfo();
    },
    mounted() {
      GoogleAnalytics.viewBuyPage({
        type: this.routeQuery.type,
        id: this.routeQuery.id,
        from: this.$storage.get('form_fullPath'),
      })
    },
    beforeDestroy() {
      this.clearCache();
      this.clearCb?.();
    }
  }
</script>

<style scoped lang="scss">
  @import "src/assets/css/mixins";
  .buy_screen {
    --secondary: #003876;
    --section-label_line_color: #003876;
    --section-label_color: #757A80;
    --font-color: #101010;
    --second-font-color: #fff;

    margin: 2rem 2rem 4rem;
    font-style: normal;
    font-family: 'Roboto';
    text-transform: none;
    color: var(--section-label_color);
    .submit_button{
      width: 100%;
      background-color: var(--secondary);
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      box-shadow: none;
      text-transform: none;
      margin-bottom: 1.3rem;
      &:hover{
        box-shadow: none;
      }
      &:disabled{
        filter: opacity(0.6) grayscale(.5) brightness(1.7) contrast(1.9);
      }
    }
    font-weight: 400;
  }

  .purchase_steps {
    margin-bottom: 2rem;
    text-align: left;
  }

  .payment_grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 1.3rem;
    .stripe{
      grid-area: stripe;
    }
    .action{
      grid-area: action;
    }
    .preview{
      grid-area: preview;
      height: auto;
    }
    grid-template-areas:
    'stripe preview'
    'action preview';
    &.reverse{
      grid-template-areas: 'action preview';
    }
    @include media-max(sm){
      grid-template-columns: 1fr;
      grid-template-areas:
      'preview'
      'stripe'
      'action'
    }
  }
  .policy{
    margin-top: 1.3rem;
    .warning-text{
      font-size: .8rem;
      text-align: left;
      p {
        margin-bottom: .5rem;
      }
    }
  }

  .submit_label{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-size: .9rem;
    hr{
      flex-grow: 1;
      background-color: var(--section-label_line_color);
    }
  }
  .process_encrypted{
    line-height: 1.1rem;
    font-weight: 400;
    max-width: 28rem;
    margin: 0 auto 1.3rem;
  }
  .error{
    color: rgb(209, 29, 29);
    font-weight: 400;
    font-size: .8rem;
    margin-bottom: 1rem;
  }

  .repeat {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    background-color: rgba(0, 0, 0, .15);
  }

  .repeat-popup {
    width: 30rem;
    background-color: var(--settings-body-background);
    color: var(--settings-body-text-color);
    border-radius: .2rem;
    box-shadow: 0 0 7px rgba(0, 0, 0, .1), 0 0 15px rgba(0, 0, 0, .2);
    padding: 2rem 1rem;
    position: relative;

    .close {
      width: 2rem;
      padding: .25rem .5rem;
      position: absolute;
      z-index: 1;
      top: .5rem;
      right: .5rem;
      font-size: 1.5rem;
      cursor: pointer;

      svg {
        height: 100%;
      }
    }
  }
  .home_btn{
    color: var(--second-font-color);
    background-color: var(--secondary);
    font-size: 1.3rem;
    padding: 1.3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5rem;
    @include media-max(sm){
      margin-top: 1.5rem;
    }
    &:hover{
      color: var(--second-font-color);
      text-decoration: none;
    }
  }

  .payment_processing{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2010;
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--second-font-color);
    font-size: 1.5rem;
    text-transform: uppercase;
    backdrop-filter: blur(10px);
  }
</style>